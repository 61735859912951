<template>
  <div class="app">
    <div class="title">
        <h1>
          CTF入門操作体験
        </h1>
    </div>
    <div class="container">
        <div class="courses-desc">
            CTFやLinux操作が始めての方向けに、CELTFの実践前に簡単な操作ガイド付きで実体験を行うためのサイトになります。<br>
            「CTFを始めたけどそもそもLinuxを操作した事がない」「どうやってフラグを見つければ良いか見当もつかない」といった方にまずは体験してもらう事を目標にしています。<br>
            実際に体験した上で、興味を持ってもらえたら実際に環境を構築しCELTFや他のCTFに挑戦してもらえたらと考えています。<br>
            <br>
            ※ 「CTF入門操作体験」はログインが必要なく、すべてのコンテンツはブラウザ上で機能します。<br>
            ※ Linux操作はブラウザ上で動作するエミュレータを利用します。<br>
            ※ ブラウザ操作はChromeの利用を前提としています。<br>
            ※ 現在アクセスできないコースに関しては、今後のアップデートで開放を予定しています。<br>
        </div>
        <div class="courses">
            <div class="course enable_course">
                <div class="course-label">
                    <h3 class="label-title">Linux基本操作</h3>
                </div>
                <div class="course-desc">
                    Linuxの基本操作を体験する事ができます。<br>
                </div>
                <div class="course-contents">
                    <router-link class="btn btn-primary course-content" to="/stage/linux/filesystem/1">ファイルの操作</router-link>
                    <router-link class="btn btn-primary course-content" to="/stage/linux/user_group/1">ユーザーとグループ</router-link>
                    <router-link class="btn btn-primary course-content" to="/stage/linux/manage_process/1">プロセス管理</router-link>
                </div>
            </div>
            <div class="course enable_course">
                <div class="course-label">
                    <h3 class="label-title">ネットワーク操作</h3>
                </div>
                <div class="course-desc">
                    Linuxを利用したネットワークの基本操作を体験する事ができます。<br>
                </div>
                <div class="course-contents">
                    <router-link class="btn btn-primary course-content disabled" to="/">ネットワーク管理</router-link>
                    <router-link class="btn btn-primary course-content disabled" to="/">TCPを使用した接続</router-link>
                </div>
            </div>
            <div class="course enable_course">
                <div class="course-label">
                    <h3 class="label-title">プログラミング</h3>
                </div>
                <div class="course-desc">
                    Linuxを利用した簡単なプログラミングを体験する事ができます。<br>
                </div>
                <div class="course-contents">
                    <router-link class="btn btn-primary course-content disabled" to="/">Bashスクリプト</router-link>
                    <router-link class="btn btn-primary course-content disabled" to="/">Pythonスクリプト</router-link>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
        course: {},
    }
  },
  methods: { 
  },
  mounted () {
  }
}
</script>

<style scoped>
.title {
  margin-top: 24px;
  margin-bottom: 24px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.courses-desc {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 1024px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.courses {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.course {
  width: 340px;
  height: 340px;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
  transition: 0.3s;
}

.enable_course:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}

.course-label {
}

.course-contents {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-content {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.course-desc {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  height: 60px;
}

.label-title {
  margin-top: 20px;
}

</style>
